<template>
    <footer class="text-center py-3 mt-2">
        &copy; {{ year }} www.esekai.com
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data(){
        return{
            year: ''
        }
    },
    mounted(){
        var date = new Date()
        this.year = date.getFullYear()
    }
}
</script>

<style scoped>
footer{
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.7);
}
</style>