<template>
    <section>
        <div class="square"></div>
        <div class="container my-3">
            <div class="row">
                <div class="col-lg-6">
                    <h1 class="title">About us</h1>
                </div>
                <div class="col-lg-6 d-lg-none d-flex">
                    <img src="../assets/images/about.png" alt="about" width="90%" class="mx-auto">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-lg-7">
                    <p class="about-text">
                        We are an IT solutions company specialized in providing analysis, advice, and solutions for organizations and individuals who are seeking development or improvement to their communication, data, or software systems.<br><br>
                        Services we provide includes but is not limited to:<br>
                        <ul class="about-list">
                            <li>Installing and configuring software and computer systems.</li>
                            <li>Troubleshooting and resolving software or hardware issues.</li>
                            <li>Providing general technical support to the organization.</li>
                            <li>Analyzing records and logs to spot underlying trends and potential issues.</li>
                            <li>Networking and wireless communications.</li>
                            <li>Backup solutions and disaster recovery.</li>
                            <li>Virtualization of your IT environment.</li>
                            <li>Cloud environment solutions.</li>
                            <li>Remote working solutions.</li>
                        </ul>
                        We are committed to assist our customers with technical leadership in the installation, upgrade, configuration, and implementation of products. In this ever-changing technological world, our endeavor is to help our clients adapt to these rapid technological advancements. We do so by providing solutions to better connect employees and enhance communication and productivity.<br><br>
                        Furthermore, we provide training for employees to adapt to the implementation of new technology and cater a solution that best fits your organization. We also offer adoption of cloud technologies and we can execute efficient and effective plans for your AWS cloud adoption journey.
                    </p>
                </div>
                <div class="col-lg-5 d-none d-lg-block">
                    <img src="../assets/images/about.png" alt="about" width="110%">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutPage'
}
</script>

<style scoped>
.square{
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100vw;
    height: 80vh;
    clip-path: polygon(0 0, 100% 0, 100% 40%, 0% 80%);
    background-color: #dadada;
}
.title{
    font-size: 3rem;
    margin-top: 3rem;
}
.about-text{
    text-align: start;
    font-size: 1.2rem;
}
.about-list li{
    font-size: 1.1rem;
}

@media only screen and (max-width: 450px){
    .square{
        clip-path: polygon(0 0, 100% 0, 100% 30%, 0% 40%);
    }
    .title{
        margin-top: 2rem;
        font-size: 2.5rem;
    }
    .about-text{
        font-size: 1.15rem;
    }
}
</style>