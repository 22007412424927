<template>
  <section>
    <div class="container text-center mt-3">
        <h1>Products</h1>
        <p class="text-secondary">We specialize in many products and services, here are some examples of the many offerings we provide.</p>
            
      <div class="row justify-content-center mt-5">

        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.microsoft.com/en-ww/microsoft-365">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/microsoft-365.jpg" alt="logo">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Microsoft 365</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.microsoft.com/en-us/windows">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/windows.png" alt="logo">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Microsoft windows</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.vmware.com">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/vmware.png" alt="logo">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Vmware</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.cisco.com/">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/cisco.png" alt="logo" style="width: 80%;">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Cisco</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://meraki.cisco.com/">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/cisco-meraki.png" alt="logo" style="width: 70%;">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Cisco Meraki</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://aws.amazon.com/">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/aws.png" alt="logo" style="width: 80%;">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">AWS</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.veeam.com/">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/veeam.png" alt="logo" style="width: 80%;">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Veeam</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.android.com/">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/android.png" alt="logo" style="width: 60%;">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Android</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.lenovo.com">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/lenovo.png" alt="logo" style="width: 80%;">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Lenovo</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.apc.com">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/apc.png" alt="logo" style="width: 70%;">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">APC</h5>
                    </div> -->
                </div>
            </a>
        </div>
        <div class="col-6 col-sm-6 col-md-4 col-lg-3 mb-2">
            <a href="https://www.ui.com/">
                <div class="card">
                    <div class="card-img">
                        <img class="card-img-top" src="../assets/images/products/unifi.png" alt="logo" style="width: 35%;">
                    </div>
                    <!-- <div class="card-body">
                        <h5 class="card-title">Unifi systems</h5>
                    </div> -->
                </div>
            </a>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ProductsPage",
};
</script>

<style scoped>
section{
    margin-top: 3.5rem;
}
.card{
    cursor: pointer;
}
.card:hover{
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
.card .card-img{
    max-height: 10rem;
    min-height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 1rem 0;
}
.card-title, .card-title:hover{
    text-decoration: none !important;
    color: black;
}

@media only screen and (max-width: 600px){
    .card .card-img{
        min-height: 10rem;
        max-height: 10rem;
    }
}
</style>