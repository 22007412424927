<template>
  <div id="app">
    <NavBar />
    <router-view/>
    <Footer />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
/* global css */
@import url('./assets/css/styles.css');
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: SFPro, "Helvetica Neue", sans-serif !important;
  color: #000 !important;
  background: rgba(243, 243, 243, 0.5);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
  /* margin-top: 60px; */
}
</style>
