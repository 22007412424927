<template>
  <section class="navbar px-3">
    <!-- <h5 :style="currentNav=='HomePage'?'color: white;':'color: black;'">E-SEKAI</h5> -->
    <router-link v-if="currentNav != 'HomePage'" to="/"><img src="../assets/images/logo.png" alt="" width="45px" @click="scroll2top()"></router-link>
    <img v-else src="../assets/images/logo.png" alt="" width="45px" @click="scroll2top()" style="cursor: pointer;">

    <div class="menu-btn ml-auto" @click="toggleMenu">
      <div class="r1 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
      <div class="r2 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
      <div class="r3 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
      <div class="r4 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
      <div class="r5 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
      <div class="r6 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
      <div class="r7 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
      <div class="r8 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
      <div class="r9 mx-auto" :style="menuOpened?currentNav=='HomePage'?'background-color:white;':'background-color:white;':currentNav=='HomePage'?'background-color:white;':'background-color:black;'"></div>
    </div>

    <div class="menu-screen" :class="menuOpened?'menu-screen-show':''">
      <ul id="link-list">
        <li @click="closeMenu" :class="menuOpened?'slide-left slide-1':''"><router-link to="/" class="no-link">Home &gt;</router-link></li>
        <li @click="closeMenu" :class="menuOpened?'slide-left slide-2':''"><router-link to="/about" class="no-link">About us &gt;</router-link></li>
        <li @click="closeMenu" :class="menuOpened?'slide-left slide-3':''"><router-link to="/products" class="no-link">Products &gt;</router-link></li>
        <li @click="closeMenu" :class="menuOpened?'slide-left slide-4':''"><router-link to="/pricing" class="no-link">Pricing &gt;</router-link></li>
        <li @click="closeMenu" :class="menuOpened?'slide-left slide-5':''"><router-link to="/contact" class="no-link">Contact us &gt;</router-link></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "NavBar",
  data(){
      return{
          currentNav: this.$route.name,
          menuOpened: false,
      }
  },
  methods:{
    toggleMenu(){
      this.menuOpened = !this.menuOpened
      console.log('c');
      let menuList = document.querySelector('.menu-screen')
      if(this.menuList){
        menuList.classList.add('menu-screen-show')
      }else{
        menuList.classList.remove('menu-screen-show')
      }
    },
    closeMenu(){
      this.menuOpened = false
    },
    scroll2top(){
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  },
  watch:{
      $route (){
        this.currentNav = this.$route.name;
        window.scrollTo(0,0);
      }
  } 
};
</script>

<style scoped>
.navbar{
  height: 3.5rem;
  width: 100%;
  /* background-color: rgb(139, 139, 139); */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
}
.menu-btn{
  width: 2.6rem;
  height: 2.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  position: relative;
  cursor: pointer;
  z-index: 5;
}
.menu-btn div{
  position: relative;
  width: 6px;
  height: 6px;
  background: rgb(243, 184, 36) !important;
  border-radius: 5px;
}

.menu-screen{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  display: none;
  justify-content: center;
  align-items: center;
}
.menu-screen-show{
  display: grid;
}
.menu-screen ul{
  list-style: none;
  padding: 0;
  text-align: center;
  position: relative;
}
.menu-screen ul li{
  margin-bottom: 0.8rem;
}
.menu-screen ul li .no-link{
  color: white;
  font-size: 1.4rem;
}

.slide-left{
  animation: slideLeft 0.5s ease-in-out;
}
.slide-1{animation-delay: 0;}
.slide-2{animation-delay: 0.1s;}
.slide-3{animation-delay: 0.2s;}
.slide-4{animation-delay: 0.3s;}
.slide-5{animation-delay: 0.4s;}
@keyframes slideLeft {
  from{
    padding-left: 5rem;
    opacity: 0;
  }
  to{
    padding-left: 0;
    opacity: 1;
  }
}
</style>