<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
                <h1 class="title">Contact us</h1>
            </div>
            <div class="col-lg-6 d-lg-none d-flex">
                <img src="../assets/images/contact.png" alt="about" width="90%" class="mx-auto">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-5 d-none d-lg-block">
                <img src="../assets/images/contact.png" alt="about" width="110%">
            </div>
            <div class="col-lg-1"></div>
            <!-- <div class="col-12 col-lg-6">
                <b-form @submit="onSubmit" @reset="onReset">
                    <b-form-group
                        id="name"
                        label="Name:"
                        label-for="name"
                    >
                        <div class="row">
                            <div class="col-6">
                                <b-form-input
                                    id="name"
                                    v-model="form.name.first"
                                    type="text"
                                    placeholder="First name"
                                    required
                                    ></b-form-input>
                            </div>
                            <div class="col-6">
                                <b-form-input
                                    id="name"
                                    v-model="form.name.last"
                                    type="text"
                                    placeholder="Last name"
                                    required
                                    ></b-form-input>
                            </div>
                        </div>
                    </b-form-group>

                    <b-form-group
                        class="mt-2"
                        id="email"
                        label="Email:"
                        label-for="Email"
                        description="We'll never share your email with anyone else."
                    >
                        <b-form-input
                        id="email"
                        v-model="form.email"
                        type="email"
                        placeholder="Enter email"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        class="mt-2 mb-3"
                        id="msg"
                        label="Comment or message:"
                        label-for="msg"
                    >
                        <b-form-textarea
                            id="msg"
                            v-model="form.message"
                            placeholder="Type your message..."
                            rows="8"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-button type="submit" variant="primary" style="margin-right: 0.8rem;">Submit</b-button>
                    <b-button type="reset" variant="danger">Reset</b-button>
                </b-form>
            </div> -->

            <div class="col text-center contacts">
                <div>
                    <h2>For more info</h2>
                    <div class="row mt-2 justify-content-center">
                        <div class="col-10 col-sm-auto">
                            <a href="mailto:justin@esekai.com">
                                <button class="contact-btn"><b-icon icon="envelope" scale="1.3" shift-h="-2"></b-icon> justin@esekai.com</button>
                            </a>
                        </div>
                        <div class="col-10 col-sm-auto mt-2 mt-sm-0">
                            <a href="tel:+808 785-1160">
                                <button class="contact-btn"><b-icon icon="telephone" scale="1.2" shift-h="-2"></b-icon> 808 785-1160</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactPage',
    // data(){
    //     return {
    //         form:{
    //             email: "",
    //             name: {
    //                 first: "",
    //                 last: ""
    //             },
    //             message: ""
    //         }
    //     }
    // },
    // methods: {
    //   onSubmit(event) {
    //     event.preventDefault()
    //     alert(JSON.stringify(this.form))
    //   },
    //   onReset(event) {
    //     event.preventDefault()
    //     // Reset our form values
    //     this.form.email = ''
    //     this.form.name.first = ''
    //     this.form.name.last = ''
    //     this.form.message = ''
    //   }
    // }
}
</script>

<style scoped>
.title{
    font-size: 3rem;
    /* margin-top: 3rem; */
}
.contacts{
    display: grid;
    align-items: center;
}
.contact-btn{
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: solid 2px #ffcba3;
    border-radius: 5px;
    color: #000;
    font-weight: 500;
    transition: 0.1s;
}
.contact-btn:hover{
    background-color: #ffcba3;
    color: black;
}
@media only screen and (max-width: 450px){
    .title{
        margin-top: 2rem;
        font-size: 2.5rem;
    }
}
</style>