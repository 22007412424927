import Vue from 'vue';
import Router from 'vue-router';

// pages
import HomePage from './screens/HomePage.vue';
import AboutPage from './screens/AboutPage.vue';
import ProductsPage from './screens/ProductsPage.vue';
import PricingPage from './screens/PricingPage.vue';
import ContactPage from './screens/ContactPage.vue';
import ErrorPage from './screens/ErrorPage.vue';

Vue.use(Router);

let router = new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: HomePage
        },
        {
            path: '/about',
            name: 'AboutPage',
            component: AboutPage
        },
        {
            path: '/products',
            name: 'ProductsPage',
            component: ProductsPage
        },
        {
            path: '/pricing',
            name: 'PricingPage',
            component: PricingPage
        },
        {
            path: '/contact',
            name: 'ContactPage',
            component: ContactPage
        },
        {
            path: '*',
            name: 'ErrorPage',
            component: ErrorPage
        }
    ],
});

export default router;