import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Particles from "particles.vue"

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Importing AOS animations
import AOS from 'aos'
import 'aos/dist/aos.css'

// Make BootstrapVue available throughout the project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// particles
Vue.use(Particles);

//Import Global Components
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'
// Pages
import Products from './screens/ProductsPage.vue'
import Pricing from './screens/PricingPage.vue'
import Contact from './screens/ContactPage.vue'

//Register Components
Vue.component('NavBar', NavBar)
Vue.component('Footer', Footer)
// Register Pages
Vue.component('ProductsPage', Products)
Vue.component('PricingPage', Pricing)
Vue.component('ContactPage', Contact)

Vue.config.productionTip = false

new Vue({
  created () {
    AOS.init()
  },
  router,
  render: h => h(App),
}).$mount('#app')
