<template>
    <section>
        <div class="container my-0 my-md-3 text-center">
            <h1>Find the perfect plan for you</h1>
            <p class="text-secondary">Our pricing models are flexible. Choose the plan that's right for you. Whether you're getting started or well down the path to personalization, we've got you covered.</p>
            <div class="row justify-content-center mt-5">
                <div class="col-12 col-md-6 col-lg-3 mb-3">
                    <b-card title="Hourly or daily rate" class="text-center">
                        <div class="card-decorator card-1"></div>
                        <b-card-text class="card-text">
                            We provide affordable professional rates depending on requirements.
                        </b-card-text>
                        <div class="price mt-5 pt-4">Contact us for a Quote</div>
                        <router-link to="/contact"><button class="card-btn btn-1">Select</button></router-link>
                    </b-card>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-3">
                    <b-card title="Monthly retainer" class="text-center">
                        <div class="card-decorator card-2"></div>
                        <b-card-text class="card-text">
                            If you are interested in on-demand service, this may be for you.
                        </b-card-text>
                        <div class="price mt-5 pt-4">Contact us for a Quote</div>
                        <router-link to="/contact"><button class="card-btn btn-2">Select</button></router-link>
                    </b-card>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-3">
                    <b-card title="Value based" class="text-center">
                        <div class="card-decorator card-3"></div>
                        <b-card-text class="card-text">
                            Need assistance with planning or another opinion on a project or issue?
                        </b-card-text>
                        <div class="price mt-5 pt-4">Contact us for a Quote</div>
                        <router-link to="/contact"><button class="card-btn btn-3">Select</button></router-link>
                    </b-card>
                </div>
                <div class="col-12 col-md-6 col-lg-3 mb-3">
                    <b-card title="Project based" class="text-center">
                        <div class="card-decorator card-4"></div>
                        <b-card-text class="card-text">
                            Do you have a specific project you need assistance with?
                        </b-card-text>
                        <div class="price mt-5 pt-4">Contact us for a Quote</div>
                        <router-link to="/contact"><button class="card-btn btn-4">Select</button></router-link>
                    </b-card>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'PricingPage',
}
</script>

<style scoped>
section{
    margin-top: 4.5rem;
}
.card{
    background-color: transparent;
    border-radius: 8px;
    overflow: hidden;
}
.card-decorator{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 45%;
    z-index: -1;
}
.card-1{
    background-color: #efe2ff;
}
.card-2{
    background-color: #f1e8e1;
}
.card-3{
    background-color: #dde8f0;
}
.card-4{
    background-color: #bad9d7;
}
.card .card-text{
    color: rgb(112, 112, 112);
}
.card .price{
    font-size: 1.8rem;
}
.card .price .ds{
    color: rgb(92, 92, 92);
    font-size: 1.2rem;
}
.card .card-btn{
    padding: 0.5rem 2rem;
    margin-top: 2rem;
    font-size: 1.2rem;
    border: solid 2px;
    border-radius: 500px;
}
.card .btn-1{
    border-color: #efe2ff;
}
.card .btn-1:hover{
    background-color: #efe2ff;
}
.card .btn-2{
    border-color: #f1e8e1;
}
.card .btn-2:hover{
    background-color: #f1e8e1;
}
.card .btn-3{
    border-color: #dde8f0;
}
.card .btn-3:hover{
    background-color: #dde8f0;
}
.card .btn-4{
    border-color: #bad9d7;
}
.card .btn-4:hover{
    background-color: #bad9d7;
}
</style>